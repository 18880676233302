import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "gatsby"

// Components
import {
  RichText,
} from "../../components/";

class Products extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_products" className="px-6 py-12 relative">
        <div className="cContainer">
          <h1>{data.heading}</h1>
          <div className="mt-3">
            <GatsbyImage
              alt={data.logo.title}
              image={data.logo.gatsbyImageData}
            />
          </div>
          <div className="mt-3">
            <RichText json={JSON.parse(data.body.raw)} references={data.body.references} />
          </div>
          <div className="mt-10 flex items-center justify-center">
            <Link
              className="button"
              to={data.ctaLink}
            >
              {data.ctaLabel}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Products.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Products;
