import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  RichText,
} from "../../components/";

class Evidence extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_evidence" className="px-6 py-12 relative">
        <div className="cContainer">
          <div className="flex items-end justify-between">
            <h1>{data.heading}</h1>
            <span className="!hidden md:!block apsBadge w-60 h-60"></span>
          </div>
          <div className="mt-16">
            <RichText json={JSON.parse(data.body.raw)} />
          </div>
        </div>
        <div className="-mx-6 py-6 mt-16 bg-green">
          <div className="cContainer">
            <h2 className="text-white text-center">{data.figureTitle}</h2>
          </div>
        </div>
        <div className="cContainer">
          <div className="mt-16">
            <GatsbyImage
              alt={data.figureImage.title}
              image={data.figureImage.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    );
  }
}

Evidence.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Evidence;
