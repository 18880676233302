import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  RichText,
} from "../../components/";

class Testimonial extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_testimonial" className="py-12 relative">
        <div className="cContainer">
          <GatsbyImage
            alt={data.image.title}
            image={data.image.gatsbyImageData}
          />
          <div className="relative max-w-[90%] mx-auto -mt-6 p-8 text-white text-center bg-brown shadow-testimonial">
            <div className="text-h4 md:text-p-xl xl:text-p-xxl">
              <RichText json={JSON.parse(data.testimonialQuote.raw)} />
            </div>
            <p className="mt-4">-{data.testimonialName}</p>
            <p>{data.testimonialJob}</p>
          </div>
        </div>
      </div>
    );
  }
}

Testimonial.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Testimonial;
