// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby"

// Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Hero extends React.Component {
  render() {
    const data = this.props.data;
    const carouselSettings = {
      arrows: false,
      autoplay: true,
      dots: true,
      infinite: true,
      pauseOnFocus: true,
      pauseOnHover: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      vertical: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            vertical: false,
            dots: false
          }
        }
      ]
    };

    return (
      <div id="section_hero" className="pb-16 md:pb-32" data-hero-section>
        <div className="relative z-10 cContainer">
          {data.slides.length >1 &&
          <Slider className="advanced-carousel" {...carouselSettings}>
            {data.slides.map((slide, index) => {
              return (
                <div className="relative text-center px-6 md:px-12 xl:px-36" key={index}>
                  <GatsbyImage
                    alt={slide.image.title}
                    image={slide.image.gatsbyImageData}
                    loading="eager"
                  />
                  <div className="relative md:absolute left-0 right-12 md:right-hero-text-right bottom-0 p-4 bg-white text-green text-left -mt-6 md:mt-0 mr-10 md:mr-0 pb-12 md:pb-4">
                    <span className="apsBadge w-8 md:w-12 h-8 md:h-12"></span>
                    <div className="mt-4 text-p md:text-p-xl xl:text-p-xxl font-light">{slide.text.text}</div>
                  </div>
                  <Link
                    className="button md:w-[340px] absolute md:static bottom-0 right-16"
                    to={slide.ctaLink}
                  >
                    {slide.ctaLabel}
                  </Link>
                </div>
              );
            })}
          </Slider>}

          {/* ------ old code to uncomment out once website out of construction -------- */}
          {/* {data.slides.length === 1 &&
          <div className="relative text-center px-6 md:px-12 xl:px-36">
            <GatsbyImage
              alt={data.slides[0].image.title}
              image={data.slides[0].image.gatsbyImageData}
              loading="eager"
            />
            <div className="relative md:absolute left-0 right-12 md:right-hero-text-right bottom-0 p-4 bg-white text-green text-left -mt-6 md:mt-0 mr-10 md:mr-0 pb-12 md:pb-4">
              <span className="apsBadge w-8 md:w-12 h-8 md:h-12"></span>
              <div className="mt-4 text-p md:text-p-xl xl:text-p-xxl font-light">{data.slides[0].text.text}</div>
            </div>
            {data.slides[0].catLink && <Link
              className="button md:w-[340px] absolute md:static bottom-0 right-16"
              to={data.slides[0].ctaLink}
            >
              {data.slides[0].ctaLabel}
            </Link>}
          </div>
          } */}
          {data.slides.length === 1 &&
          <div className="relative text-center px-6 md:px-12 xl:px-36">
            <GatsbyImage
              alt={data.slides[0].image.title}
              image={data.slides[0].image.gatsbyImageData}
              loading="eager"
            />
            <div className="relative w-full md:absolute left-0 right-12 md:right-hero-text-right bottom-[-18%] p-4 bg-white text-green text-left -mt-6 md:mt-0 mr-10 md:mr-0 pb-12 md:pb-4">
              <span className="apsBadge w-8 md:w-12 h-8 md:h-12"></span>
              <div className="mt-4 text-p md:text-p-xl xl:text-p-xxl font-light">{data.slides[0].text.text}</div>
            </div>
            {data.slides[0].catLink && <Link
              className="button md:w-[340px] absolute md:static bottom-0 right-16"
              to={data.slides[0].ctaLink}
            >
              {data.slides[0].ctaLabel}
            </Link>}
          </div>
          }
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
