import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  RichText,
} from "../../components/";

class OurVision extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_vision" className="px-6 py-12 relative" data-vision-section>
        <div className="cContainer">
          <h1>{data.heading}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="pt-6">
              <RichText json={JSON.parse(data.body.raw)} />
              <div className="hidden md:block mt-24 -mr-12 full-screen-bg z-10 bg-brown text-white">
                <div className="py-12" data-vision-testimonial>
                  <div className="font-italic text-h1-m">
                    <RichText json={JSON.parse(data.testimonialQuote.raw)} />
                  </div>
                  <div className="w-[400px] mx-auto mt-6 text-right">
                    <p className="font-manrope">-{data.testimonialName}</p>
                    {data.testimonialJob && <p className="font-manrope">{data.testimonialJob}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <GatsbyImage
                alt={data.image.title}
                image={data.image.gatsbyImageData}
              />
              <div className="md:hidden bg-brown text-white w-[80%] mx-auto p-6 -mt-16" data-vision-testimonial>
                <div className="font-italic text-h4 text-center">
                  <RichText json={JSON.parse(data.testimonialQuote.raw)} />
                </div>
                <div className="max-w-[260px] mx-auto mt-6 text-right">
                  <p className="font-manrope">-{data.testimonialName}</p>
                  <p className="font-manrope">{data.testimonialJob}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OurVision.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OurVision;
