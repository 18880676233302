import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  RichText,
} from "../../components/";

class ContactUs extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_contact" className="pt-20 relative">
        <div className="lg:absolute left-0 top-0 w-full h-full overflow-hidden" data-bg-gatsby-image>
          <GatsbyImage
            alt={data.backgroundImage.title}
            image={data.backgroundImage.gatsbyImageData}
            layout="fullWidth"
          />
        </div>
        <div className="relative cContainer">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-14 items-end">
            <div className="bg-brown text-white p-6 lg:p-14">
              <span className="apsBadge w-8 lg:w-16 h-8 lg:h-16"></span>
              <h1>{data.heading}</h1>
              <h3 className="mt-4">{data.subheading}</h3>
              <div className="mt-12">
                <RichText json={JSON.parse(data.body.raw)} />
              </div>
              <div className="hidden lg:block">
                <ul className="mt-8">
                  <li className="flex">
                    <span className="w-12">
                      <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.00917 10.9488C6.6156 10.2873 7.64353 10.2426 8.30509 10.849L26.707 27.7175L45.109 10.849C45.7706 10.2426 46.7985 10.2873 47.4049 10.9488C48.0114 11.6104 47.9667 12.6383 47.3051 13.2448L27.8051 31.1198C27.1838 31.6893 26.2303 31.6893 25.609 31.1198L6.10899 13.2448C5.44742 12.6383 5.40273 11.6104 6.00917 10.9488Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.58203 12.0469C5.58203 11.1494 6.30957 10.4219 7.20703 10.4219H46.207C47.1045 10.4219 47.832 11.1494 47.832 12.0469V39.6719C47.832 40.5338 47.4896 41.3605 46.8801 41.97C46.2706 42.5795 45.444 42.9219 44.582 42.9219H8.83203C7.97008 42.9219 7.14343 42.5795 6.53393 41.97C5.92444 41.3605 5.58203 40.5338 5.58203 39.6719V12.0469ZM8.83203 13.6719V39.6719H44.582V13.6719H8.83203Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.35 25.5735C24.9566 26.235 24.9121 27.2629 24.2507 27.8695L8.81319 42.0273C8.15177 42.6339 7.12383 42.5895 6.51724 41.928C5.91064 41.2666 5.95509 40.2387 6.61652 39.6321L22.054 25.4743C22.7154 24.8677 23.7434 24.9121 24.35 25.5735Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M29.0641 25.5735C29.6707 24.9121 30.6986 24.8677 31.3601 25.4743L46.7976 39.6321C47.459 40.2387 47.5034 41.2666 46.8968 41.928C46.2902 42.5895 45.2623 42.6339 44.6009 42.0273L29.1634 27.8695C28.502 27.2629 28.4575 26.235 29.0641 25.5735Z" fill="white"/>
                      </svg>
                    </span>
                    <div className="mt-2 ml-6 flex-1">
                      <a href="mailto:info@animalplantsciences.com">info@animalplantsciences.com</a>
                    </div>
                  </li>
                  <li className="flex mt-16">
                    <span className="w-12">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.207 15.3604C21.7735 15.3604 19.8008 17.3331 19.8008 19.7666C19.8008 22.2001 21.7735 24.1729 24.207 24.1729C26.6405 24.1729 28.6133 22.2001 28.6133 19.7666C28.6133 17.3331 26.6405 15.3604 24.207 15.3604ZM16.8633 19.7666C16.8633 15.7108 20.1512 12.4229 24.207 12.4229C28.2629 12.4229 31.5508 15.7108 31.5508 19.7666C31.5508 23.8224 28.2629 27.1104 24.207 27.1104C20.1512 27.1104 16.8633 23.8224 16.8633 19.7666Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.207 6.54785C20.7012 6.54785 17.339 7.94054 14.86 10.4195C12.381 12.8985 10.9883 16.2608 10.9883 19.7666C10.9883 25.8471 14.3838 31.3839 17.9716 35.5099C19.7476 37.5522 21.5273 39.1989 22.8633 40.3345C23.3917 40.7836 23.849 41.1514 24.207 41.4307C24.5651 41.1514 25.0223 40.7836 25.5507 40.3345C26.8868 39.1989 28.6665 37.5522 30.4425 35.5099C34.0303 31.3839 37.4258 25.8471 37.4258 19.7666C37.4258 16.2608 36.0331 12.8985 33.5541 10.4195C31.0751 7.94054 27.7129 6.54785 24.207 6.54785ZM24.207 43.2666C23.3648 44.4698 23.3643 44.4696 23.3639 44.4692L23.3627 44.4684L23.3592 44.466L23.3484 44.4583L23.311 44.4317C23.2793 44.4091 23.234 44.3765 23.1761 44.3342C23.0603 44.2497 22.8939 44.1263 22.684 43.966C22.2646 43.6454 21.6708 43.1762 20.9609 42.5727C19.543 41.3675 17.6509 39.6177 15.755 37.4374C11.999 33.1181 8.05078 26.9048 8.05078 19.7666C8.05078 15.4817 9.75295 11.3723 12.7828 8.34241C15.8127 5.31252 19.9221 3.61035 24.207 3.61035C28.4919 3.61035 32.6013 5.31252 35.6312 8.34241C38.6611 11.3723 40.3633 15.4817 40.3633 19.7666C40.3633 26.9048 36.4151 33.1181 32.6591 37.4374C30.7632 39.6177 28.871 41.3675 27.4532 42.5727C26.7432 43.1762 26.1495 43.6454 25.73 43.966C25.5202 44.1263 25.3538 44.2497 25.238 44.3342C25.1801 44.3765 25.1348 44.4091 25.103 44.4317L25.0657 44.4583L25.0548 44.466L25.0514 44.4684L25.0502 44.4692C25.0497 44.4696 25.0493 44.4698 24.207 43.2666ZM24.207 43.2666L25.0502 44.4692C24.5445 44.8232 23.8696 44.8232 23.3639 44.4692L24.207 43.2666Z" fill="white"/>
                      </svg>
                    </span>
                    <div className="mt-1 ml-6 flex-1">
                      <p>One Commerce Center</p>
                      <p>1201 N Orange Street, Suite 762, Wilmington, New Castle County, DE</p>
                      <p>United States of America</p>
                    </div>
                  </li>
                </ul>
                <p className="flex flex-wrap mt-6 text-h3">
                  Follow us on LinkedIn: 
                  <a className="ml-4" href="https://www.linkedin.com">
                    <svg width="30" height="30" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3_94)">
                        <path d="M3.6328 15V4.87953H0.202501V15H3.6328ZM1.9181 3.49692C3.1143 3.49692 3.85888 2.71978 3.85888 1.74862C3.8366 0.755565 3.11435 0 1.9408 0C0.767426 0 0.000114441 0.75558 0.000114441 1.74862C0.000114441 2.71983 0.744512 3.49692 1.8957 3.49692H1.91799H1.9181ZM5.53146 15H8.96177V9.34824C8.96177 9.04576 8.98405 8.7436 9.07464 8.52738C9.32262 7.92304 9.88704 7.29712 10.8346 7.29712C12.0759 7.29712 12.5725 8.2252 12.5725 9.5857V14.9999H16.0026V9.19695C16.0026 6.08834 14.3103 4.64193 12.0533 4.64193C10.2028 4.64193 9.39032 5.65626 8.93894 6.34712H8.96184V4.87932H5.53154C5.57656 5.82897 5.53154 14.9998 5.53154 14.9998L5.53146 15Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_3_94">
                          <rect width="16" height="15" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
            <div className="px-6 pb-6 bg-brown lg:bg-transparent">
              <form name="Contact Us" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                <input name="form-name" type="hidden" value="Contact Us"/>
                <div className="grid grid-cols-2 gap-8">
                  <div>
                    <input type="text" id="first_name" name="first_name" placeholder="First Name" />
                  </div>
                  <div>
                    <input type="text" id="last_name" name="last_name" placeholder="Last Name" />
                  </div>
                </div>
                <div className="mt-4">
                  <input type="email" id="email" name="email" placeholder="Email" />
                </div>
                <div className="mt-4">
                  <textarea id="message" name="message" rows="5" placeholder="Message"></textarea>
                </div>
                <div className="mt-8 text-center">
                  <button className="button button--primary" type="submit">Submit</button>
                </div>
              </form>

              <div className="lg:hidden text-white">
                <ul className="mt-8">
                  <li className="flex flex-col items-center text-center">
                    <span className="w-12">
                      <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.00917 10.9488C6.6156 10.2873 7.64353 10.2426 8.30509 10.849L26.707 27.7175L45.109 10.849C45.7706 10.2426 46.7985 10.2873 47.4049 10.9488C48.0114 11.6104 47.9667 12.6383 47.3051 13.2448L27.8051 31.1198C27.1838 31.6893 26.2303 31.6893 25.609 31.1198L6.10899 13.2448C5.44742 12.6383 5.40273 11.6104 6.00917 10.9488Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.58203 12.0469C5.58203 11.1494 6.30957 10.4219 7.20703 10.4219H46.207C47.1045 10.4219 47.832 11.1494 47.832 12.0469V39.6719C47.832 40.5338 47.4896 41.3605 46.8801 41.97C46.2706 42.5795 45.444 42.9219 44.582 42.9219H8.83203C7.97008 42.9219 7.14343 42.5795 6.53393 41.97C5.92444 41.3605 5.58203 40.5338 5.58203 39.6719V12.0469ZM8.83203 13.6719V39.6719H44.582V13.6719H8.83203Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.35 25.5735C24.9566 26.235 24.9121 27.2629 24.2507 27.8695L8.81319 42.0273C8.15177 42.6339 7.12383 42.5895 6.51724 41.928C5.91064 41.2666 5.95509 40.2387 6.61652 39.6321L22.054 25.4743C22.7154 24.8677 23.7434 24.9121 24.35 25.5735Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M29.0641 25.5735C29.6707 24.9121 30.6986 24.8677 31.3601 25.4743L46.7976 39.6321C47.459 40.2387 47.5034 41.2666 46.8968 41.928C46.2902 42.5895 45.2623 42.6339 44.6009 42.0273L29.1634 27.8695C28.502 27.2629 28.4575 26.235 29.0641 25.5735Z" fill="white"/>
                      </svg>
                    </span>
                    <div className="">
                      <a href="mailto:info@animalplantsciences.com">info@animalplantsciences.com</a>
                    </div>
                  </li>
                  <li className="flex flex-col items-center text-center mt-16">
                    <span className="w-12">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.207 15.3604C21.7735 15.3604 19.8008 17.3331 19.8008 19.7666C19.8008 22.2001 21.7735 24.1729 24.207 24.1729C26.6405 24.1729 28.6133 22.2001 28.6133 19.7666C28.6133 17.3331 26.6405 15.3604 24.207 15.3604ZM16.8633 19.7666C16.8633 15.7108 20.1512 12.4229 24.207 12.4229C28.2629 12.4229 31.5508 15.7108 31.5508 19.7666C31.5508 23.8224 28.2629 27.1104 24.207 27.1104C20.1512 27.1104 16.8633 23.8224 16.8633 19.7666Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.207 6.54785C20.7012 6.54785 17.339 7.94054 14.86 10.4195C12.381 12.8985 10.9883 16.2608 10.9883 19.7666C10.9883 25.8471 14.3838 31.3839 17.9716 35.5099C19.7476 37.5522 21.5273 39.1989 22.8633 40.3345C23.3917 40.7836 23.849 41.1514 24.207 41.4307C24.5651 41.1514 25.0223 40.7836 25.5507 40.3345C26.8868 39.1989 28.6665 37.5522 30.4425 35.5099C34.0303 31.3839 37.4258 25.8471 37.4258 19.7666C37.4258 16.2608 36.0331 12.8985 33.5541 10.4195C31.0751 7.94054 27.7129 6.54785 24.207 6.54785ZM24.207 43.2666C23.3648 44.4698 23.3643 44.4696 23.3639 44.4692L23.3627 44.4684L23.3592 44.466L23.3484 44.4583L23.311 44.4317C23.2793 44.4091 23.234 44.3765 23.1761 44.3342C23.0603 44.2497 22.8939 44.1263 22.684 43.966C22.2646 43.6454 21.6708 43.1762 20.9609 42.5727C19.543 41.3675 17.6509 39.6177 15.755 37.4374C11.999 33.1181 8.05078 26.9048 8.05078 19.7666C8.05078 15.4817 9.75295 11.3723 12.7828 8.34241C15.8127 5.31252 19.9221 3.61035 24.207 3.61035C28.4919 3.61035 32.6013 5.31252 35.6312 8.34241C38.6611 11.3723 40.3633 15.4817 40.3633 19.7666C40.3633 26.9048 36.4151 33.1181 32.6591 37.4374C30.7632 39.6177 28.871 41.3675 27.4532 42.5727C26.7432 43.1762 26.1495 43.6454 25.73 43.966C25.5202 44.1263 25.3538 44.2497 25.238 44.3342C25.1801 44.3765 25.1348 44.4091 25.103 44.4317L25.0657 44.4583L25.0548 44.466L25.0514 44.4684L25.0502 44.4692C25.0497 44.4696 25.0493 44.4698 24.207 43.2666ZM24.207 43.2666L25.0502 44.4692C24.5445 44.8232 23.8696 44.8232 23.3639 44.4692L24.207 43.2666Z" fill="white"/>
                      </svg>
                    </span>
                    <div className="">
                      <p>One Commerce Center</p>
                      <p>1201 N Orange Street, Suite 762, Wilmington, New Castle County, DE</p>
                      <p>United States of America</p>
                    </div>
                  </li>
                </ul>
                <p className="flex flex-col items-center mt-6 text-p">
                  Follow us on LinkedIn: 
                  <a className="ml-4" href="https://www.linkedin.com">
                    <svg width="24" height="24" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3_94)">
                        <path d="M3.6328 15V4.87953H0.202501V15H3.6328ZM1.9181 3.49692C3.1143 3.49692 3.85888 2.71978 3.85888 1.74862C3.8366 0.755565 3.11435 0 1.9408 0C0.767426 0 0.000114441 0.75558 0.000114441 1.74862C0.000114441 2.71983 0.744512 3.49692 1.8957 3.49692H1.91799H1.9181ZM5.53146 15H8.96177V9.34824C8.96177 9.04576 8.98405 8.7436 9.07464 8.52738C9.32262 7.92304 9.88704 7.29712 10.8346 7.29712C12.0759 7.29712 12.5725 8.2252 12.5725 9.5857V14.9999H16.0026V9.19695C16.0026 6.08834 14.3103 4.64193 12.0533 4.64193C10.2028 4.64193 9.39032 5.65626 8.93894 6.34712H8.96184V4.87932H5.53154C5.57656 5.82897 5.53154 14.9998 5.53154 14.9998L5.53146 15Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_3_94">
                          <rect width="16" height="15" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactUs.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactUs;
