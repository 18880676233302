import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  RichText,
} from "../../components/";

class Partnership extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div id="section_partnership" className="px-6 py-12 relative">
        <div className="cContainer">
          <h1>{data.heading}</h1>
          <div className="mt-20">
            <GatsbyImage
              alt={data.image.title}
              image={data.image.gatsbyImageData}
            />
          </div>
          <div className="mt-20">
            <RichText json={JSON.parse(data.body.raw)} />
          </div>
        </div>
      </div>
    );
  }
}

Partnership.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Partnership;
