import { graphql } from "gatsby";
import * as React from "react";
import get from "lodash/get";

// Components
import Layout from "../layout/";

// Sections
import {
  ContactUs,
  Evidence,
  Hero,
  OurVision,
  Partnership,
  Products,
  Team,
  Testimonial,
} from "../sections/";

const sections = {
  ContactUs: ContactUs,
  Evidence: Evidence,
  Hero: Hero,
  OurVision: OurVision,
  Partnership: Partnership,
  Products: Products,
  Team: Team,
  Testimonial: Testimonial,
};

class StandardPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulPageStandard");

    return (
      <Layout
        seo={{
          description: page_data.description ? page_data.description.description : undefined,
          path: page_data.path,
          title: page_data.title,
        }}
      >
        {page_data.sections.map((section, index) => {
          const Section = sections[section.internal.type.replace("ContentfulSection", "")];

          return (
            <Section
              data={section}
              key={index}
            />
          );
        })}
      </Layout>
    );
  }
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query StandardPageBypathQuery($path: String!) {
    contentfulPageStandard(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      sections {
        ... on Node {
          ... on ContentfulSectionHero {
            internal {
              type
            }
            id
            slides {
              image {
                title
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              text {
                text
              }
            }
          }
        }
      }
    }
  }
`;

// old graphql query - just holding in case

// ... on ContentfulSectionOurVision {
//   internal {
//     type
//   }
//   id
//   heading
//   body {
//     raw
//   }
//   image {
//     title
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }
//   testimonialQuote {
//     raw
//   }
//   testimonialName
// }
// ... on ContentfulSectionPartnership {
//   internal {
//     type
//   }
//   id
//   heading
//   image {
//     title
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }
//   body {
//     raw
//   }
// }
// ... on ContentfulSectionTestimonial {
//   internal {
//     type
//   }
//   id
//   image {
//     title
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }
//   testimonialQuote {
//     raw
//   }
//   testimonialName
//   testimonialJob
// }
// ... on ContentfulSectionTeam {
//   internal {
//     type
//   }
//   id
//   heading
//   members {
//     division
//     image {
//       title
//       gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//     }
//     name
//     role
//     bio {
//       raw
//     }
//     isApsTeam
//   }
// }
// ... on ContentfulSectionProducts {
//   internal {
//     type
//   }
//   id
//   heading
//   logo {
//     title
//     gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
//   }
//   body {
//     raw
//     references {
//       contentful_id
//       gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
//       title
//     }
//   }
//   ctaLabel
//   ctaLink
// }
// ... on ContentfulSectionEvidence {
//   internal {
//     type
//   }
//   id
//   heading
//   body {
//     raw
//   }
//   figureTitle
//   figureImage {
//     title
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }
// }
// ... on ContentfulSectionContactUs {
//   internal {
//     type
//   }
//   id
//   heading
//   subheading
//   body {
//     raw
//   }
//   backgroundImage {
//     title
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }
// }
