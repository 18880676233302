import PropTypes from "prop-types";
import * as React from "react";

// Components
import {
  TeamMember,
} from "../../components/";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.toggleBio = this.toggleBio.bind(this);
    this.state = {
      bio_opened: false
    };
  }

  toggleBio() {
    this.setState({
      bio_opened: !this.state.bio_opened
    })
  }

  render() {
    const data = this.props.data;

    return (
      <div id="section_team" className="px-6 py-20">
        <div className="cContainer">
          <h1>{data.heading}</h1>
          {data.members.map((member, index) => {
            return (
              <TeamMember data={member} key={index} />
            );
          })}
        </div>
      </div>
    );
  }
}

Team.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Team;
